import type { ComponentType } from "react"

export function TruncatedText(Component): ComponentType {
    return (props) => {
        const truncatedTextPrefix = "Lines:"
        const name = props["data-framer-name"]
        if (name?.startsWith(truncatedTextPrefix)) {
            const lines = name
                .replace(new RegExp("^" + truncatedTextPrefix), "")
                .trim()

            return (
                <Component
                    {...props}
                    style={{
                        ...props.style,
                        display: "-webkit-box",
                        WebkitLineClamp: lines,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                />
            )
        }

        return <Component {...props} />
    }
}
